.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.submit-Button {
  box-shadow: "none";
  text-transform: "none";
  background-color: "red";
  border-color: "#006aa4";
}
.submit-Button:hover {
    background-color: "white";
    border: "1px solid #006aa4";
    color: "#006aa4";
    box-shadow: "none";
  }
  .submit-Button:hover:active {
    box-shadow: "none";
    background-color: "#006aa4";
    border-color: "#006aa4";
  }
  .submit-Button:focus {
    box-shadow: "0 0 0 0.2rem rgba(0,123,255,.5)";
  }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



