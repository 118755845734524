:root {
  --acte-color-primary: #006aa4;
  --acte-color-secondary: #56b953;
  --acte-color-orange: #f47c3c;
  --acte-color-red: #da292e;
  --acte-color-dark: #343a40;
  --acte-color-gray: #6c757d;
  --acte-color-green: #2aa198;
  --acte-color-divider: rgba(0, 0, 0, 0.12);
}



html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 81px !important;
  /* Margin bottom by footer height */
}


.app-container {
  background: #f1f4f6;
  min-height: calc(100vh - 81px);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 81px;
  /* Set the fixed height of the footer here */
  line-height: 1.5;
  background-color: #fafbfc;
  ;
  color: #f8f9fa;
  border-top: #e9ecef solid 1px;
}


.linear-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1301;
  width: "100%";
  & > * + * {
    margin-top: 2px;
  }
} 

/* App Header Bar */
/*#region*/

.app-header {
  background-color: rgb(255, 255, 255) !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.app-top-bar {
  padding-top: 6px;
  padding-bottom: 6px;
}

.app-top-bar-image {
  padding-left: .5rem;
  padding-right: .5rem;
  margin: auto;
  display: block;
}

.app-top-bar-title {
  text-transform: uppercase !important;
  font-weight: 200 !important;
}


/*#endregion*/

/* Nav Bar */
/*#region*/

.nav-toolbar {
  background-color: var(--acte-color-primary) !important;
  border-bottom: 5px solid var(--acte-color-secondary) !important;
  height: 45px !important;
  width: 100%;
  min-height: 45px !important;
  padding: 0 !important;
}

.nav-link {
  color: rgba(255, 255, 255, .75) !important;
  margin: .125rem !important;
  padding: .5rem !important;
  text-decoration: none;
  line-height: 1.75;
  font-size: .875rem !important;
  text-transform: none !important;
  border-radius: 0 !important;
}

.nav-link.active {
  color: white !important;
  background-color: var(--acte-color-secondary) !important;
}

.nav-link:hover {
  color: white !important;
}

.nav-button {
  color: rgba(255, 255, 255, .75) !important;
}

.nav-button:hover {
  color: white !important;
}

/*#endregion*/

/* breadcrumb */
/*#region*/

.page-title-breadcrumb {
  background: rgba(255, 255, 255, .45);
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.breadcrumb-link {
  color: var(--acte-color-secondary) !important;
  font-size: .875rem;
}

.breadcrumb-text {
  display: inline !important;
  font-size: .875rem !important;
}

.page-title {
  padding: 0.5rem 0;
}

/*#endregion*/




.main-section {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}


/* Tabs */
/*#region*/



.tab-header.active {
  color: var(--acte-color-primary);
  border-bottom: 2px solid var(--acte-color-primary);
}

.tab-header:hover {
  color: var(--acte-color-secondary);
  border-bottom: 2px solid var(--acte-color-secondary);
}

.tab-box {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.tab-description {
  color: var(--acte-color-gray) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: .875rem !important;
  margin: 0;
}

/*#endregion*/

/* Tab Toolbar & Buttons */
/*#region*/

.toolbar-button {
  padding: 0.25rem 0.5rem !important;
  text-transform: none !important;
  /* text-decoration: none !important; */
  border-radius: 0px !important;
}

.Mui-disabled.toolbar-button {
  color: var(--acte-color-gray) !important;
}

.toolbar-export-button,
.toolbar-filter-button,
.toolbar-stat-button {
  color: var(--acte-color-dark) !important;
}

.toolbar-export-button:hover,
.toolbar-filter-button:hover,
.toolbar-stat-button:hover {
  color: white !important;
  background-color: var(--acte-color-dark) !important;
}

.toolbar-map-button,
.toolbar-comment-button,
.toolbar-edit-button,
.toolbar-add-button {
  color: var(--acte-color-primary) !important;
}

.toolbar-map-button:hover,
.toolbar-comment-button:hover,
.toolbar-edit-button:hover,
.toolbar-add-button:hover {
  color: white !important;
  background-color: var(--acte-color-primary) !important;
}


.toolbar-delete-button {
  color: var(--acte-color-red) !important;
}

.toolbar-delete-button:hover {
  color: white !important;
  background-color: var(--acte-color-red) !important;
}

.switch-button {
  padding: 0.25rem 0.5rem !important;
  margin-left: 0 !important;
}


.switch-button .MuiFormControlLabel-label {
  font-size: .875rem !important;
  color: var(--acte-color-dark) !important;
}

.toolbar-text {
  font-size: .875rem !important;
  color: var(--acte-color-dark) !important;
  padding: 0.25rem 0.5rem !important;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toolbar-select {
  padding: 4px 24px 5px 0 !important;
}


/*#endregion*/

/* Data Table  */
/*#region*/

.MuiDataGrid-cell {
  white-space: pre-line;
}

.table-link-cell {
  color: var(--acte-color-primary) !important;
}

.table-logo-cell {
  width: 115px !important;
  height: 34px !important;
  object-fit: contain !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cell-buttons {
  display: flex !important;
  justify-content: flex-end !important;
}

.cell-icon {
  cursor: pointer;
  padding: .125rem;
  margin: .125rem;
}

.cell-default-icon {
  color: var(--acte-color-primary);
}

.cell-default-icon:hover {
  color: white;
  background-color: var(--acte-color-primary);
}

.cell-delete-icon {
  color: var(--acte-color-red);
}

.cell-delete-icon:hover {
  color: white;
  background-color: var(--acte-color-red);
}

/*#endregion*/

/* Info Table  */
/*#region*/

.info-cell {
  padding: 0.25rem 0.5rem;
  font-size: .875rem !important;
}

.info-cell:not(:last-child) {
  border-bottom: 1px solid var(--acte-color-divider) !important;
}

.info-cell-label {
  font-weight: 600;
}

.info-cell-logo {
  margin-right: 1rem;
  width: 115px !important;
  height: 34px !important;
  object-fit: contain !important;
}

.info-cell-image {
  margin-top: 5px;
  margin-bottom: 5px;
}

.info-cell-help {
  color: var(--acte-color-gray) !important;
  font-style: italic;
}

/*#endregion*/

/* Input */
/*#region*/

.MuiInputBase-inputSizeSmall {
  font-size: .875rem !important;
}

.MuiSelect-select.MuiInputBase-inputSizeSmall:not(.MuiSelect-multiple) {
  height: 1.4375em !important;
}

.checkbox-input-text {
  font-size: 0.875rem !important;
}

/*#endregion*/


/* Text */
/*#region*/

.switch-label .MuiFormControlLabel-label {
  font-size: .875rem !important;
  color: var(--acte-color-dark) !important;
}

.sm-text {
  font-size: .875rem !important;
}

.xs-text {
  font-size: .75rem !important;
}

.text-muted {
  color: var(--acte-color-gray) !important
}

/*#endregion*/

/* Dialog */
/*#region*/

.delete-dialog-icon {
  font-size: 3rem !important;
  width: 48px !important;
  height: 48px !important;
  color: #dc3545 !important;
  /* transform: translateY(50%) !important */
}

.delete-message {
  font-size: .875rem !important;
  color: var(--acte-color-gray) !important;
  margin-bottom: .5rem !important;
}

.dialog-button {
  background-color: white !important;
  text-transform: none !important;
  font-size: 0.875rem !important;
  border-radius: 0px !important;
}

.dialog-delete-button {
  color: var(--acte-color-red) !important;
}

.dialog-delete-button:hover {
  color: white !important;
  background-color: var(--acte-color-red) !important;
}

.dialog-edit-button {
  color: var(--acte-color-primary) !important;
}

.Mui-disabled.dialog-edit-button {
  color: var(--acte-color-gray) !important;
}

.dialog-edit-button:hover {
  color: white !important;
  background-color: var(--acte-color-primary) !important;
}

.dialog-cancel-button {
  color: var(--acte-color-dark) !important;
}

.dialog-cancel-button:hover {
  text-decoration: underline !important;
}

.group-buttons {
  margin: .25rem !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.filter-group-buttons {
  margin: .25rem !important;
  display: flex !important;
  justify-content: space-between !important;
}

/*#endregion*/

/* Filter & Graph Panels */
/*#region*/

.chart-accordion,
.filter-accordion {
  border: 1px solid var(--acte-color-divider) !important;
}

.chart-accordion:not(:last-child),
.filter-accordion:not(:last-child) {
  border-bottom: 0 !important;
}

.stat-chart-text,
.stat-pki-text,
.accordion-header {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
}

.filter-accordion .MuiAccordionSummary-content .MuiBadge-badge {
  transform: scale(1) translate(125%, 0%);
  border: 1px solid white;
  padding: 0 4px;
  color: white;
  background-color: var(--acte-color-secondary)
}

.MuiPaper-root.MuiAccordion-root:before {
  opacity: 0 !important;
}

.stat-pki-box {
  padding: .5rem 1rem;
  border-left: 1px solid var(--acte-color-divider);
  border-right: 1px solid var(--acte-color-divider);
  border-top: 1px solid var(--acte-color-divider);
}

.stat-pki-value {
  color: var(--acte-color-primary) !important;
  font-weight: 500 !important;
  font-size: 1.5rem !important;
}

.stat-pki-note {
  color: var(--acte-color-gray) !important;
  font-size: .875rem !important;
}

.no-data-text {
  color: var(--acte-color-gray) !important;
  font-size: .875rem !important;
}

.filter-checklist {
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter-checklist-text {
  font-size: 0.875rem !important;
}

.stat-chart-box {
  padding: .5rem 1rem;
  border: 1px solid var(--acte-color-divider);
}

.filter-chip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*#endregion*/

/* Forms */
/*#region*/

.form-label {
  padding-top: calc(0.25rem + 1px) !important;
  padding-bottom: calc(0.25rem + 1px) !important;
  font-size: .875rem !important;
  line-height: 1.5 !important;
  white-space: normal !important;
}

.form-radio-label {
  font-size: .875rem !important;
  line-height: 1.5 !important;
  white-space: normal !important;
}

.login-input {
  padding: "4px";
}

/*#endregion*/

.map-button {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding-right: .5rem;
}

.map-clear-button {
  font-size: 1.1rem !important;
  color: var(--acte-color-red);
  cursor: pointer;
  padding: "5px"

}

.map-clear-button:hover {
  background-color: var(--acte-color-red);
  color: white
}

.map-add-button {
  font-size: 1.1rem !important;
  color: var(--acte-color-primary);
  cursor: pointer;
  padding: "25px";
}

.map-add-button:hover {
  background-color: var(--acte-color-primary);
  color: white
}

.home-intro-box {
  /* margin-top: 1rem;
  margin-bottom: 1rem; */
  padding: 32px 32px;
  /* position: relative; */
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}


.home-blue-box {
  background-image: radial-gradient(circle at -10% 50%, #b4cbed, 20%, #006aa4 67%);
}

.home-gray-box {
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: #fff !important;
}



.home-outline-box {
  border: 2px solid var(--acte-color-primary);
  margin: 1rem;
  background-color: #fff !important;
}

.home-intro-text {
  color: #fff;
  margin: 1.5rem 0 !important;
}

.home-blue-link {
  border-radius: 3px;
  border: solid 2px rgba(255, 255, 255, 0.85);
  font-size: .875rem;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 11px 18px;
  text-align: center;
  color: #fff !important;
  transition: all 0.5s ease;
}

.home-blue-link:hover {
  background: #fff;
  text-decoration: none !important;
  color: #006aa4 !important;
  cursor: pointer;
}

.home-green-link {
  color: rgb(86, 185, 83) !important;
  text-decoration: none !important;
}

.home-green-link:hover {
  text-decoration: underline !important;
}

.home-tn-img {
  margin: 0 2rem;
  width: 110px !important;
  height: 200px !important;
  object-fit: contain !important;
}

.home-select-button {
  color: var(--acte-color-primary);
  text-transform: none !important;
  background-color: white !important;
  border-color: var(--acte-color-primary) !important;
  font-size: 0.875rem !important;
}

.home-select-button:hover {
  color: white !important;
  background-color: var(--acte-color-primary) !important;
}

.commune-logo-large {
  object-fit: contain !important;
  display: block;
  max-width: 128px;
  max-height: 128px;
  width: auto;
  height: auto;
}

.commune-logo-meduim {
  object-fit: contain !important;
  display: block;
  max-width: 64px;
  max-height: 64px;
  width: auto;
  height: auto;
}

.svg-tn-map {
  width: 100%;
  height: auto;
  max-height: 360px;
  stroke: var(--acte-color-primary);
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.svg-tn-map-gouv {
  fill: white;
  cursor: pointer;
}


.svg-tn-map-gouv:focus {
  fill: var(--acte-color-primary);
  outline: 0;
}

.svg-tn-map-gouv:hover {
  fill: var(--acte-color-secondary);
  outline: 0;
}

.svg-tn-map-gouv[aria-checked=true] {
  fill: var(--acte-color-primary);
  outline: 0;
}

.svg-tn-map-tooltip {
  position: fixed;
  z-index: 100;
  color: var(--acte-color-gray) !important;
  font-size: .875rem !important;
}

.svg-tn-map-bloc {
  z-index: 0;
}

.commune-radio-group {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  max-height: 336px;
  overflow-x: hidden;
  overflow-y: auto;
}

@media screen and (max-width: 600px) {
  .commune-radio-group {
    max-height: 280px;
  }
}

@media screen and (max-width: 500px) {
  .commune-radio-group {
    max-height: 224px;
  }
}

.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
  height: 100%;
}


.ag-header-group-cell-label {
  justify-content: center;
}


.ag-header-center .ag-header-cell-label {
  justify-content: center;
}

.ag-default-cell {
  display: flex !important;
  align-items: center !important;
  line-height: 2 !important;
  padding: .5rem !important;
  white-space: normal !important;
}

.ag-number-cell {
  text-align: right;
  justify-content: flex-end;
}

.ag-gray-cell {
  background-color: var(--acte-color-divider);
}

.ag-blue-cell {
  background-color: rgba(0, 106, 164, 0.25)
}

.ag-yellow-cell {
  background-color: rgba(244, 240, 60, 0.25)
}

.ag-red-cell {
  background-color: rgba(218, 41, 46, 0.25)
}

.ag-spin-cell {
  background: white;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}

.ag-clicked-cell {
  border: 2px solid !important;
  border-color: var(--acte-color-secondary) !important;
  border-style: solid !important;
  outline: initial !important;
}

.ag-dense-cell {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.card-box {
  background-color: white;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  border-radius: .125rem;
  height: 100%;
}

.card-header {
  padding: .5rem .625rem;
  border-bottom: 1px solid rgba(26, 54, 126, .125);
}

.card-title {
  font-size: 1.1rem !important;
  color: var(--acte-color-dark);
}

.card-content {
  padding: .75rem 1rem;
  height: 100%;
}

.dashboard-energy {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.dashboard-energy-text {
  font-size: 1rem !important;
  font-weight: 600 !important;
  padding-left: .125rem;
}

.dashboard-cell {
  padding: .5rem .25rem !important;
}

.dashboard-cell.row-divider:not(:last-child) {
  border-bottom: 1px solid lightgrey !important;
}

.dashboard-cell.divider {
  border-right: lightgrey solid 0;
  border-bottom: lightgrey solid 1px
}

@media(min-width:600px) {
  .dashboard-cell.divider:nth-last-child(-n+2) {
    border-bottom-width: 0
  }

  .dashboard-cell.divider:nth-child(1n) {
    border-right-width: 1px
  }

  .dashboard-cell.divider:nth-child(2n) {
    border-right-width: 0
  }
}

.dashboard-cell.divider:nth-last-child(-n+1) {
  border-bottom-width: 0
}

.dashboard-number {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dashboard-number-progress {
  margin-bottom: .25rem;
  width: 100px;
}

.dashboard-number-text {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  /* inline-size: min-content; */
  text-align: center;
  white-space: pre-wrap;
}

.dashboard-number-value {
  font-weight: 700 !important;
  font-size: 1.5rem !important;
}

.dashboard-number-blue {
  color: var(--acte-color-primary) !important;
}

.dashboard-number-green {
  color: var(--acte-color-secondary) !important;
}

.dashboard-number-note {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  text-align: center;
  color: var(--acte-color-gray) !important;
}

.dashboard-group-text {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
}

.dashboard-pie-chart {
  width: auto;
  height: 100%;
  /* max-height: 200px; */
}

.dashboard-pie-chart-legend ul {
  margin: 0px;
  padding: 0px;
}

.dashboard-pie-chart-legend.column-legend ul {
  display: flex;
  flex-direction: column;
  /* max-height: 170px;
  overflow-y: auto; */
}

@media screen and (max-width: 900px) {
  .dashboard-pie-chart-legend.column-legend ul {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .dashboard-pie-chart-legend.column-legend li {
    padding-left: .5rem;
  }
}

.dashboard-pie-chart-legend.column-legend li {
  align-items: center;
  /* cursor: pointer; */
  display: flex;
  flex-direction: row;
}

.dashboard-pie-chart-legend.inline-legend ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dashboard-pie-chart-legend.inline-legend li {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.dashboard-pie-chart-legend span {
  display: inline-block;
  height: 16px;
  margin-right: 5px;
  width: 16px;
  border-radius: 50%;
  flex-shrink: 0;
}

.dashboard-pie-chart-legend p {
  margin: 0px;
  padding: 0px;
  font-size: .875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-slider {
  padding: 0 3rem !important;
}

.dashboard-map-tooltip {
  height:30px ;
  color: var(--acte-color-gray) !important;
  font-size: .875rem !important;
}

.dashboard-map {
  width: 100%;
  height: auto;
  max-height: 500px;
  stroke: white;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}

[class*=dashboard-map-location-]:hover {
  opacity: 0.5;
}

.dashboard-map-location-nodata {
  fill: var(--acte-color-divider);
}

.dashboard-map-location-tep-8 {
  fill: #03045e;
}

.dashboard-map-location-tep-7 {
  fill: #023e8a;
}

.dashboard-map-location-tep-6 {
  fill: #0077b6;
}

.dashboard-map-location-tep-5 {
  fill: #0096c7;
}

.dashboard-map-location-tep-4 {
  fill: #00b4d8;
}

.dashboard-map-location-tep-3 {
  fill: #48cae4;
}

.dashboard-map-location-tep-2 {
  fill: #90e0ef;
}

.dashboard-map-location-tep-1 {
  fill: #ade8f4;
}

.dashboard-map-location-dt-8 {
  fill: #004b23;
}

.dashboard-map-location-dt-7 {
  fill: #006400;
}

.dashboard-map-location-dt-6 {
  fill: #007200;
}

.dashboard-map-location-dt-5 {
  fill: #008000;
}

.dashboard-map-location-dt-4 {
  fill: #38b000;
}

.dashboard-map-location-dt-3 {
  fill: #70e000;
}

.dashboard-map-location-dt-2 {
  fill: #9ef01a;
}

.dashboard-map-location-dt-1 {
  fill: #ccff33;
}

/* ["03045e","023e8a","0077b6","0096c7","00b4d8","48cae4","90e0ef","ade8f4"] */
/* ["004b23","006400","007200","008000","38b000","70e000","9ef01a","ccff33"] */

.video-header{
  padding: 0.25rem 0.5rem !important;
  color: var(--acte-color-primary) !important;
  font-weight: 600 !important;
}

.video-desc {
  padding: 0 0.5rem !important;
  color: var(--acte-color-gray) !important;
  font-style: italic;
  font-size: .875rem !important;
}
